.save-car {
  position: absolute;
  bottom: 0px;

  display: flex;
  justify-content: space-between;
  width: 94%;
  margin: 0 3% 8px;

  .save-car__saved {
    background: #0b3464;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 3px 6px;

    &.show {
      visibility: visible;
    }
    &.hidden {
      visibility: hidden;
    }

    i {
      margin: 0;
      color: white;
    }
  }

  .save-car__btn {
    border: none;
    outline: none;
    border-radius: 10px;
    color: #0b3464;
    font-weight: bold;
    cursor: pointer;

    i {
      font-weight: bold;
    }
  }
}
