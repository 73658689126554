.search-filter__container {
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 0 0 1em !important;
  box-shadow: 1px 1px 1px #ccc;

  h3:first-child {
    font-size: 19px;
    color: #00ebff;
    text-align: center;
    padding: 12px 16px;
    background: #0b3464;
    margin: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-weight: 400;

    i {
      color: white;
      font-size: 18px;
    }
  }

  .reset-filter {
    cursor: pointer;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 2px;
    background: #0b3464;
    color: white;
    padding: 7px 14px;
    font-weight: bold;

    div {
      font-size: 14px;
    }
  }
  .title-div {
    border-bottom: 1px solid #aaa;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      cursor: pointer;
      display: none;
    }
    .title {
      padding: 5px 1em;
      margin: 0;
      color: #3b74b5;
      font-size: 1.125em;
    }
    i {
      color: #0b3564 !important;
    }
  }

  .search-filter {
    padding: 0.5em 1em;
    border: 1px solid rgba(34, 36, 38, 0.15);
    margin: 0.5em 1em 0em;
    color: #3b74b5 !important;
    font-family: "Roboto", sans-serif;

    h5 {
      font-family: "Roboto", sans-serif;
    }
  }

  .search-filter--options {
    label {
      width: 24%;
      display: inline-block;
    }

    .ui.input {
      width: 75%;
    }
  }
}

.popup .ui.three {
  max-width: 900px;
}

@media screen and (max-width: 767.5px) {
  .search-filter__container {
    padding: 0 !important;
    .title-div {
      div {
        display: block;
      }
    }
    .filter-form-inactive {
      display: none;
    }
    .filter-form-active {
      display: block;
      padding: 0 0 1em;
    }
  }
}
