.footer {
  background: #0b3464;
  padding: 50px 35px;

  .ui.container {
    div {
      flex-wrap: wrap;
    }
  }

  .ui.list {
    margin-right: 2em;
    margin-top: 1.5em;

    .item {
      margin-top: 6px;

      a {
        color: #c6c6c6;
        cursor: pointer;
      }

      &:first-child {
        font-weight: bold;
        color: #fff;
      }
    }
  }
}
