.navbar {
  display: flex;
  justify-content: space-around;
  background-color: #0b3564;
  padding: 50px 190px 25px;
  padding-bottom: 20px;
  margin-bottom: 0px !important;

  .nav-logo p {
    margin: 0.45em 0 0;
    text-transform: uppercase;
    color: #f3b343;
    font-style: italic;
  }

  .menus {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-right: 30px;
  }

  .menu-icon {
    display: none;
  }

  .advert {
    flex: 1;
    padding-left: 30px;
    cursor: pointer;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }

  .item {
    color: white;
    padding-top: 0;
    padding-bottom: 0;
    text-decoration: none;
    margin-right: 0.5em;
    cursor: pointer;

    &:hover,
    &:focus {
      color: #ccc;
    }

    &.active {
      color: #ccc;
      pointer-events: none;
    }
  }
}

.mobile,
.mobile-menu {
  display: none;
}

.mobile {
  position: absolute;
  // right: 25px;
}

@media (max-width: 1640px) {
  .navbar {
    padding: 50px 90px 25px;
  }
}

@media (max-width: 1440px) {
  .navbar {
    padding: 50px 50px 25px;
  }
}

@media (max-width: 1398.98px) {
  .navbar {
    .menus {
      padding-right: 30px;
      margin-top: 1em;
    }
    .buttons {
      display: none;
    }
  }
}

@media screen and (max-width: 1110px) {
  .navbar {
    .menu-icon {
      margin-left: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      div {
        width: 35px;
        height: 4px;
        background-color: white;
        margin: 3px 0;
      }
    }
    .menus {
      display: none;
    }
    .mobile-menu {
      position: absolute;
      background: #0b3564;
      top: 158px;
      left: 0;
      z-index: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 0;
      a {
        font-size: 16px;
        margin-top: 10px;
        .text-center {
          margin-top: 0 !important;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .navbar {
    padding: 50px 20px 25px !important;
    .advert {
      display: none;
    }
  }
}

@media (max-width: 991.98px) {
  .navbar {
    padding: 50px 50px 25px;
  }
}

@media (max-width: 891.98px) {
  .menus {
    display: none !important;
  }
  .mobile {
    display: block !important;
  }
}
