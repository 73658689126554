@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,700&display=swap");
@import "./assets/fonts/stylesheet.css";
@import "./scss/base.scss";
@import "./scss/navbar.scss";
@import "./scss/footer.scss";
@import "./scss/search-filter.scss";
@import "./scss/listing.scss";
@import "./scss/home.scss";
@import "./scss/detail.scss";
@import "./scss/advertise.scss";
@import "./scss/comingsoon.scss";
@import "./scss/contact-us.scss";

body,
h1,
h2,
h3,
h4,
h5,
p,
span,
label,
div,
button {
  font-family: "Roboto", sans-serif !important;
}

@media only screen and (min-width: 1400px) {
  .ui.container {
    width: 1272px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
