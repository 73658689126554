.pd {
  &__back {
    color: #0b3464;
    padding: 0.75em 1em;
  }

  &--tag {
    background: #55af4d;
    padding: 5px 10px;
    width: 100%;
    color: white;
  }

  &--price {
    color: #ff3e3e;
    font-size: 24px;
    margin: 0.5em 0 0;
  }

  &--heading__secondary {
    color: #0b3464 !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    margin: 0;
  }

  &--title__sub {
    color: #808080;
    font-size: 15px;
    font-weight: bold;
    margin: 0;
  }

  .ui.horizontal.list > .item:not(:last-child) {
    border-right: 1px solid #4b4b4b;
  }

  .ui.list > .item > i {
    color: #55af4d;
  }

  &__dealer {
    margin-top: 1em;
    background: #fff;

    a {
      font-size: 16px;
      color: #4b4b4b;
    }

    button {
      color: white !important;
      background: #55af4d !important;
      border-radius: 30px !important;
      padding: 0.5em !important;
      text-align: center !important;
      width: 100% !important;
      font-weight: bold !important;
      margin-top: 1em !important;
      font-size: 16px !important;
    }

    .ui.disabled.rating {
      i:focus {
        outline: none;
      }
    }

    a .icon,
    .ui.rating .active.icon {
      color: #55af4d;
    }

    a.secondary {
      border-radius: 30px !important;
    }
  }

  .accordion.ui > .title {
    border-top: 1px solid #707070;

    i {
      color: #55af4d;
      font-size: 21px;
    }
  }

  &--meta {
    border-top: 1px solid #707070;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
    font-size: 16px;

    div:first-child {
      max-width: 60%;
    }
  }
}

.specs_accordion {
  .column:not(.ui) {
    padding-top: 6px !important;
    padding-bottom: 0px !important;
    font-size: 12px;
    text-transform: uppercase;
  }

  .ui.column.grid {
    margin-left: 0;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .dots {
    display: flex;
    max-width: 400px;

    span:first-child {
      order: 1;
    }
    span:last-child {
      order: 3;
    }

    &::after {
      order: 2;
      flex-grow: 1;
      border-bottom: 1px dotted;
      content: "";
      margin: 0 2px 7px;
    }
  }
}
