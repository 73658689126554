$label-width: 220px;

.advertise {
  position: relative;
  margin-bottom: 2em;

  h1 {
    color: #0b3564 !important;
    font-size: 40px !important;
  }

  h4 {
    color: #0b3564 !important;
    font-size: 28px !important;
    font-weight: normal !important;
    padding: 10px 0px !important;
  }

  h6 {
    font-weight: 500;
    font-size: 16px;
  }

  button[type="back"] {
    border-radius: 7px !important;
    font-size: 16px !important;
    font-weight: normal !important;
    padding: 10px 40px !important;
    margin-top: 8px !important;
    font-family: "Roboto";
  }

  button[type="submit"] {
    border-radius: 7px !important;
    background: #2daeff !important;
    color: white !important;
    font-size: 22px !important;
    font-weight: normal !important;
    padding: 10px 60px !important;
    margin-top: 8px !important;
    font-family: "Roboto";
  }

  .photos-container {
    .photo {
      height: 130px;
      border: dashed;
      border-style: dashed;
      border-color: #777;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;

      img {
        height: 100%;
      }
    }
  }

  .upload-photos {
    border-radius: 30px;
    background: transparent;
    border: 1px solid #aaa;
  }

  &.step1 {
    input {
      max-width: 350px !important;
      margin-bottom: 16px !important;
      text-transform: uppercase;
    }
  }

  &.step2 {
    &.jarvis-failed h1,
    &.jarvis-failed h4 {
      margin-left: $label-width + 16px;
    }

    p > b {
      margin-left: $label-width + 16px;
      font-weight: 500;
    }

    label {
      min-width: $label-width !important;
      padding-right: 30px;
      text-align: right;
    }

    .field > .selection.dropdown,
    .field > .ui.input,
    .date-input > .field {
      width: calc(100% - #{$label-width} - 30px) !important;
    }

    .date-input > .field {
      padding-right: 0px !important;
      .ui.input {
        width: 100% !important;
      }
    }

    &.jarvis-failed button[type="back"],
    &.jarvis-failed button[type="submit"] {
      margin-left: $label-width + 14px;
    }

    .ui.pointing.label,
    .ui[class*="pointing above"].label {
      margin-top: 8px;
      margin-left: $label-width + 16px;
    }

    .ui.pointing.label:before,
    .ui[class*="pointing above"].label:before {
      border-width: 1px 0 0 1px !important;
      transform: translateX(-50%) translateY(-50%) rotate(45deg) !important;
      top: 0 !important;
      left: 50% !important;
    }
  }

  &.step3 {
    h6 {
      font-weight: normal;
      font-size: 18px;
      margin: 1em 0;
    }

    button[type="back"] {
      margin-top: 2.5em !important;
    }
  }
}

.step6 {
  h1 {
    color: #0b3564 !important;
    font-size: 36px !important;
  }

  .approve {
    display: flex !important;
    justify-content: flex-end;

    button {
      margin-top: 1em !important;
      background: #55af4d !important;
      color: white !important;
      border-radius: 0 !important;
      width: 75%;
      align-self: flex-end;
      font-size: 28px;
    }
  }

  .accordion.ui .content {
    p {
      margin-left: 1em;

      b {
        display: inline-block;
        min-width: 150px;
        text-align: right;
      }
    }
  }
}

.step7 {
  background: #f7f7f5;

  div.p-5 {
    box-shadow: 1px 1px 3px #eee;
    background: white;
  }

  .shadow {
    box-shadow: 1px 1px 3px #eee;
    background: white;
    padding: 2em 5em;
  }

  h6 {
    font-weight: normal !important;
    color: #0b3564 !important;
    font-size: 18px !important;
    margin: 0 0 1.5em 0 !important;
  }

  b {
    margin: 1em 0;
    display: block;
  }

  p {
    margin: 0;
    line-height: 150%;
  }

  button.ui.button {
    margin-top: 1em !important;
    background: #00ca1d !important;
    color: white !important;
    border-radius: 0 !important;
  }

  button[type="submit"] {
    font-size: 19px;
    font-weight: bold;
    padding-left: 70px;
    padding-right: 70px;
  }
}

.StripeCheckout {
  width: 100%;
}

.step7 h3,
.step8 h3 {
  color: #0b3564 !important;
  font-size: 24px !important;
}

.step7,
.step8 {
  margin-bottom: 2em;
}
