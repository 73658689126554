.d-flex {
  display: flex !important;
}
.flex-column {
  flex-direction: column !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

$fontSizes: (10, 12, 14, 15, 16, 24, 28, 36, 40);

@each $size in $fontSizes {
  .text-#{$size} {
    font-size: ($size * 1px) !important;
  }
}

.text-blue {
  color: #3b74b5 !important;
}

.text-bold {
  font-weight: bold !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

$spaceamounts: (0, 1, 2, 3, 4, 5);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
  .m-#{$space} {
    margin: (0.5em * $space) !important;
  }

  .p-#{$space} {
    padding: (0.5em * $space) !important;
  }

  .mx-#{$space} {
    margin-left: (0.5em * $space) !important;
    margin-right: (0.5em * $space) !important;
  }

  .px-#{$space} {
    padding-left: (0.5em * $space) !important;
    padding-right: (0.5em * $space) !important;
  }

  .my-#{$space} {
    margin-top: (0.5em * $space) !important;
    margin-bottom: (0.5em * $space) !important;
  }

  .py-#{$space} {
    padding-top: (0.5em * $space) !important;
    padding-bottom: (0.5em * $space) !important;
  }

  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: (0.5em * $space) !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: (0.5em * $space) !important;
    }
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.px-auto {
  padding-left: auto;
  padding-right: auto;
}

.mask {
  -webkit-mask-image: linear-gradient(to bottom, black 20%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 20%, transparent 100%);
}

.cursor-pointer {
  cursor: pointer !important;
}

.ui.primary.button {
  color: white !important;
  font-weight: 600 !important;
  background: #ff3e3e !important;
}

.ui.secondary.button {
  color: white !important;
  font-weight: 600 !important;
  background: #ff8900 !important;
}

.position-relative {
  position: relative !important;
}

.cursor-pointer {
  cursor: pointer;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  height: 400px;
}
