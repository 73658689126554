.car__horizontal {
  padding-top: 0px;
  box-shadow: 1px 1px 5px #ccc;
  margin: 0px 0px 1em !important;
  cursor: pointer;
  color: black;

  & > .column:not(.header) {
    padding: 0px !important;
    border: 1px solid #707070;
  }

  .header {
    background: #707070;
    padding: 0px !important;
    color: white;
    font-weight: bold;

    img {
      height: 56%;
      margin-right: 0.5em;
    }
  }

  // .more_cars {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-between;
  // }

  .price,
  .title {
    font-weight: bold;
    margin: 5px 0px !important;
  }

  .price {
    font-size: 22px;
    color: #3b74b5;
    font-family: "Roboto", sans-serif;
  }

  .title {
    color: #808080;
    font-family: "Roboto", sans-serif;
  }

  p {
    margin: 0;
    &.description {
      font-size: 12px;
      position: relative;
      max-height: 100px;
      overflow: hidden;

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(transparent 70px, #f7f7f5);
      }
    }
  }

  .company {
    border-top: 1px solid #ccc;
  }

  .tag {
    display: inline-block;
    background: #55af4d;
    vertical-align: middle;
    padding: 8px 15px;
  }

  .ui.disabled.rating {
    margin-top: 3px;
  }
  .ui.rating .active.icon {
    color: #55af4d;
  }

  .ui.horizontal.list > .item:not(:last-child) {
    border-right: 1px solid #0b3464;
  }

  .fixed__Height{
    height: 264px;
  }
}

.car__horizontal2 {
  padding-top: 0px;
  box-shadow: 1px 1px 5px #ccc;
  margin: 0px 0px 1em !important;
  cursor: pointer;
  background-color: white;

  .header {
    background: #707070;
    padding: 0px !important;
    color: white;
    font-weight: bold;

    img {
      height: 56%;
      margin-right: 0.5em;
    }
  }

  .price,
  .title {
    font-weight: bold;
    margin: 5px 0px !important;
  }

  .price {
    font-size: 18px;
    color: #3b74b5;
    font-family: "Roboto", sans-serif;
  }

  .title {
    color: #808080;
    font-family: "Roboto", sans-serif;
  }

  p {
    margin: 0;

    &.description {
      font-size: 10px;

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(transparent 150px, white);
      }
    }
  }

  .ui.horizontal.list > .item {
    font-size: 12px;

    &:not(:last-child) {
      border-right: 1px solid #0b3464;
    }
  }

  .ui.checkbox {
    label {
      color: white !important;

      &::before {
        border: 2px solid #fff !important;
        background: transparent !important;
      }
    }
    input:checked ~ label:after {
      color: white;
    }
  }

  .ui.button {
    position: absolute;
    top: -13px;
    right: -8px;
    background: no-repeat;
    color: white;
    font-size: 1.25em;
  }
}

.car__vertical {
  border-radius: 5px;
  border: 2px solid #c7c7c7;
  overflow: hidden;
  cursor: pointer;
  height: 100%;

  .price--text,
  .price--number {
    display: flex;
    justify-content: space-between;
  }

  .price--text {
    font-weight: bold;
    color: #0b8d00;
    margin: 0.5em 0.75em 0;

    p {
      margin: 0;
    }
    & > p:first-child {
      color: #808080;
    }
  }

  .price--number {
    font-weight: bold;
    color: #fff;
    margin: 0.25em 0.75em 0;

    p {
      margin: 0;
    }
    & > p:first-child {
      color: #ff3e3e;
    }
    & > p:last-child {
      background: #55af4d;
      padding: 2px 4px;
      border-radius: 3px;
    }
  }

  .title {
    color: #3b74b5;
    font-weight: bold;
    margin: 0.5em 0.75em 0;
  }

  .ui.horizontal.list > .item:not(:last-child) {
    border-right: 1px solid #4b4b4b;
  }

  .action {
    background: #0b3464;
    text-align: center;
    text-transform: uppercase;
    padding: 8px;
    color: white;
  }
}

.car__vertical2 {
  cursor: pointer;

  .price {
    font-size: 28px;
    color: #ff3e3e;
    text-align: center;
    margin: 0.25em 0 0;
  }

  .title {
    color: #0b3464;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin: 0.25em 0 0;
  }

  .subtitle {
    color: #808080;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    margin: 0.25em 0 0;
  }

  .ui.horizontal.list > .item:not(:last-child) {
    border-right: 1px solid #4b4b4b;
  }

  .action {
    margin: 0.25em 0 0;
    background: #55af4d;
    text-align: center;
    text-transform: uppercase;
    padding: 8px;
    color: white;
    border-radius: 30px;
    font-weight: 500;
    font-size: 18px;
  }
}

.car__vertical3 {
  margin: 0 !important;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;

  h4.title {
    padding: 0.8em 0;
    text-align: center;
    color: #0b3464;
    border-bottom: 1px solid #ddd;
  }

  .column:not(:first-child) {
    .car__vertical3--detail {
      border-bottom: 1px solid #ddd;
    }
  }

  .car__vertical3--detail {
    display: flex !important;
    justify-content: space-between;
    padding: 0.8em;

    p {
      margin: 0;
    }
  }
}

.listing--controlls {
  background: #0b3464;
  display: flex;
  justify-content: space-between;
  color: white;
  padding: 6px 0px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .ui.selection.dropdown {
    background: transparent;
    border: none;
    color: #00ebff;
    font-weight: bold;
  }

  .ui.selection.visible.dropdown > .text:not(.default) {
    color: #00ebff;
  }

  i.icon {
    color: #00ebff;
    cursor: pointer;
  }

  i.icon.disabled {
    color: white;
    opacity: 1 !important;
  }
}

.ui.horizontal.list > .item:not(:last-child) {
  padding-right: 5px;
}

.ui.horizontal.list > .item:not(:first-child) {
  margin-left: 5px;
}

@media only screen and (min-width: 1198.5px) and (max-width: 1400.5px){
  .car__horizontal{
    .fixed__Height{
      height: 235px;
    }
  } 
}

@media only screen and (min-width: 991.5px) and (max-width: 1198.5px){
  .car__horizontal{
    .fixed__Height{
      height: 194px;
    }
  } 
}

@media only screen and (min-width: 767.5px) and (max-width: 990.5px){
  .car__horizontal{
    .fixed__Height{
      height: 31%;
    }
  } 
}

@media only screen and (max-width: 990.5px) and (min-width: 768.5px) {
  .ui.container {
    width: 90%;
  }
}

@media screen and (max-width: 767.5px) {
  .car__horizontal {
    .more_cars {
      display: flex !important;
      width: 100% !important;
      flex-direction: row !important;
    }
    .fixed__Height{
      height: 72%;
    }
  }
}

@media screen and (max-width: 450px) {
  .listing--controlls {
    .field {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      label {
        margin-left: 10%;
      }

      .ui.selection.dropdown {
        min-width: 10em !important;
      }
    }
  }
}
