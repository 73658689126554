.head-bg {
  position: relative;

  .section--heading {
    position: absolute;
    top: 12%;
    left: 50%;
    transform: translateX(-50%);

    .search--form {
      padding: 1em;
      background: white;
      box-shadow: 1px 1px 2px #ccc;
      border-radius: 3px;

      h3 {
        color: black;
      }
      .ui.selection.dropdown {
        min-width: 0;
      }
      .ui.input input {
        width: 100% !important;
      }
    }

    .header--text {
      color: white;

      h1 {
        font-size: 36px;
      }

      p {
        font-size: 18px;
        line-height: 1.5em;
      }
    }
  }

  .ui.header {
    color: white;
  }
}

.app-bg {
  position: relative;

  .app--header {
    position: absolute;
    top: 10%;
    right: 10%;
    text-align: right;

    h1 {
      max-width: 700px;
      font-size: 40px;
      color: black !important;
    }

    p {
      max-width: 500px;
      line-height: 1.4em;
      margin-left: auto;
      font-size: 18px;
    }
  }
}

.heading-two {
  color: #4b4b4b;
  font-size: 26px;
  font-weight: bold;
}

.blog {
  box-shadow: 1px 1px 3px 0 #aaa;

  h4 {
    text-align: left;
    margin: 0.5em 1em !important;
    font-weight: normal !important;
    color: #4b4b4b !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991.5px) {
  .head-bg {
    .search--form {
      margin-right: 10px;
    }
    .section--heading {
      position: relative;
      transform: translateX(0);
      left: 0;
      .ui.grid {
        flex-direction: column-reverse !important;
      }
      .header--text {
        margin-top: -340px;
        margin-bottom: 236px;
        h1 {
          font-size: 30px;
        }

        p {
          font-size: 16px;
        }
      }

      .search--form {
        position: relative;
        box-shadow: none;
        left: 0;
      }
    }
  }
}

@media screen and (max-width: 767.5px) {
  .head-bg {
    img {
      display: none !important;
    }
    .ui.grid {
      margin: 0 !important;
    }
    .ui.container {
      margin: 0 !important;
    }
    .section--heading {
      position: relative;
      transform: translateX(0);
      left: 0;
      .ui.grid {
        flex-direction: column-reverse !important;
      }

      .header--text {
        background: url("../assets/img/head-bg.png");
        background-size: cover;
        padding: 1.5em !important;
        min-height: 300px;
        left: 0;
        h1 {
          font-size: 30px;
        }

        p {
          font-size: 16px;
        }
      }

      .search--form {
        box-shadow: none;
        left: 0;
      }
    }
  }

  .app-bg {
    img {
      display: none !important;
    }

    .app--header {
      position: static;
      text-align: center;
      margin-left: -1em !important;
      margin-right: -1em !important;
      background: url("../assets/img/app-bg.png");
      padding: 1em;

      h1 {
        font-size: 25px;
      }
      p {
        margin-right: auto;
      }
    }
  }
}
